'use strict';
$(document).ready(() => {
  //console.log('jQuery CALLED IN FILE `app/javascript/scripts/main.js`');
});

document.addEventListener('turbo:load', () => {
  console.log('slick init in turbo:load');

  let $body = $('body');

  // map


  // scroll menu

  // $(window).bind('mousewheel', function(event) {
  //   if (event.originalEvent.wheelDelta >= 0) {
  //     console.log('Scroll up');
  //   }
  //   else {
  //     console.log('Scroll down');
  //   }
  // });

  $body.on('click','.tabs-v2__item-link', function () {
    $('.tabs-v2__item-link').removeClass('tabs-v2__item-link--active');
    $(this).addClass('tabs-v2__item-link--active');
    $('.tabs-v2__block').removeClass('tabs-v2__block--active');
    $($(this).attr('data-show')).addClass('tabs-v2__block--active');
    return false;
  });


  let slickRemove = {
    onInit3: function (event, slick) {
      $(event.target).find('.slick-dots li:gt(' + (slick.slideCount - 3) + ')').hide();
    },
    onInit4: function (event, slick) {
      $(event.target).find('.slick-dots li:gt(' + (slick.slideCount - 4) + ')').hide();
    },
    onAfterChange3: function (event, slick, currentSlide) {
      let $next = $(event.target).find('.slick-next');
      if (currentSlide + 3 >= slick.slideCount) {
        $next.addClass('slick-disabled').css('pointer-events', 'none');
      } else {
        $next.removeClass('slick-disabled').css('pointer-events', 'all');
      }
    },
    onAfterChange4: function (event, slick, currentSlide) {
      let $next = $(event.target).find('.slick-next');
      if (currentSlide + 4 >= slick.slideCount) {
        $next.addClass('slick-disabled').css('pointer-events', 'none');
      } else {
        $next.removeClass('slick-disabled').css('pointer-events', 'all');
      }
    },
  };

  $('.products__carousel')
    .on('init', slickRemove.onInit3)
    .slick({
      variableWidth: false,
      slidesToShow: 3,
      slidesToScroll: 3,
      dots: true,
      infinite: false,
      speed: 500,

      responsive: [
        {
          breakpoint: 3000,
          settings: "unslick",
        }, {
          breakpoint: 800,
          settings: {
            variableWidth: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
          }
        }
      ]
    })
   .on('afterChange', slickRemove.onAfterChange4);

  // carousel main — top banner
  $('#main-carousel').slick({
    dots: true,
    infinite: true,
    speed: 500,
    // fade: true,
    //cssEase: 'linear'
  });

  // carousel awards
  $('.tile-2__carousel').slick({
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        }
      }
    ]
  });
  // products__items--mobile-slick
  $('.products__items--mobile-slick').slick({
    variableWidth: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    dots: true,
    infinite: false,
    speed: 500,

    responsive: [
      {
        breakpoint: 3000,
        settings: "unslick",
      }, {
        breakpoint: 800,
        settings: {
          variableWidth: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        }
      }
    ]
  })


  // carousel awards
  $('.awards__carousel').slick({
    dots: true,
    infinite: false,
    speed: 500,
  });

  // carousel brands
  $('.brands__carousel').slick({
    dots: true,
    variableWidth: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          variableWidth: true,
          slidesToScroll: 3,
          slidesToShow: 3,
          infinite: true,
          dots: true
        }
      }
    ]
  });
  $('.events__items--carousel').slick({
    dots: true,
    variableWidth: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          variableWidth: true,
          slidesToScroll: 3,
          slidesToShow: 3,
          infinite: false,
          dots: true
        }
      }
    ]
  })
  // carousel news
  $('.news__carousel')
    .on('init', slickRemove.onInit4)
    .slick({
      variableWidth: true,
      dots: true,
      infinite: false,
      speed: 500,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            variableWidth: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: true
          }
        }
      ]
    })
    .on('afterChange', slickRemove.onAfterChange4);

  // carousel case
  $('.stories__carousel')
    .on('init', slickRemove.onInit4)
    .slick({
      variableWidth: true,
      dots: true,
      infinite: false,
      speed: 500,
    })
    .on('afterChange', slickRemove.onAfterChange4);

  // Меню
  $body.on('click', '.js-show-menu', function () {
    if ($(this).hasClass('header__link--active')) {
      $('.header__link--active').removeClass('header__link--active')
      $(this).removeClass('header__link--active');
      $('.menu').removeClass('menu--active');
      $('.fog').removeClass('fog--active');
    } else {
      $('.header__link--active').removeClass('header__link--active')
      $(this).addClass('header__link--active');
      $('.menu').removeClass('menu--active');
      $($(this).attr('data-menu')).addClass('menu--active');
      $('.fog').addClass('fog--active');
    }
    return false
  });

  // fog
  $body.on('click', '.fog', function () {
    $('.header__link--active').removeClass('header__link--active')
    $('.menu').removeClass('menu--active');
    $('.fog').removeClass('fog--active');
    return false;
  });


  // tabs
  $body.on('click', '.tabs__link', function () {
    let $t = $(this);
    $t.parent().find('.tabs__link').removeClass('tabs__link--active');
    $t.addClass('tabs__link--active');
    $('.tab[id=' + $t.attr('href').replace(/^#/, '') + ']').parent().find('.tab--active').removeClass('tab--active');
    $($t.attr('href')).addClass('tab--active');
    $('.tabs').removeClass('tabs--mobile-show'); // hide mobile menu
    return false;
  })

  // menu mobile


  $body.on('click', '.js--burger-main', function () {
    if ($body.hasClass('js--main-show')) {
      $body.removeClass('js--main-show');
      $('.header__link--active').removeClass('header__link--active');
      $('.menu--active').removeClass('menu--active');
      $('.fog').removeClass('fog--active');
    } else {
      $body.addClass('js--main-show');
    }
    return false;
  });

  // tabs mobile
  $body.on('click', '.tabs__link-mobile-more', function () {
    let $t = $(this);
    $t.parents('.tabs').addClass('tabs--mobile-show');
    return false;
  });
  $body.on('click', '.burger--tabs', function () {
    $('.tabs').removeClass('tabs--mobile-show');
    return false;
  })

  // Акордион
  $body.on('click', '.accordion__item', function () {
    $('.accordion__item').removeClass('accordion__item--active');
    $(this).addClass('accordion__item--active');
    $('.accordion__img').removeClass('accordion__img--active');
    $($(this).attr('data-img')).addClass('accordion__img--active');
    return false;
  })

  // soft scroll
  $body.on('click', '.js--scroll', function () {
    $body.removeClass('js--main-show');
    $('.menu--active').removeClass('menu--active');
    $('.fog').removeClass('fog--active');
    $('.header__link--active').removeClass('header__link--active')

    let target = $(this).attr('href').replace('/', '');
    $('html, body').animate({
      scrollTop: $(target).offset().top - $('.header').height()
    }, 400);
    return false
  });

  //popover
  $('.hover-text').click(function () {
    return false;
  })

  const button = document.querySelector('#button');
  const tooltip = document.querySelector('#tooltip');

  const popperInstance = Popper.createPopper(button, tooltip, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  function show() {
    // Make the tooltip visible
    tooltip.setAttribute('data-show', '');

    // Enable the event listeners
    popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        {name: 'eventListeners', enabled: true},
      ],
    }));

    // Update its position
    popperInstance.update();
  }

  function hide() {
    // Hide the tooltip
    tooltip.removeAttribute('data-show');

    // Disable the event listeners
    popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        {name: 'eventListeners', enabled: false},
      ],
    }));
  }

  const showEvents = ['mouseenter', 'focus'];
  const hideEvents = ['mouseleave', 'blur'];

  if (typeof button !== 'undefined' && button !== null) {
    showEvents.forEach((event) => {
      button.addEventListener(event, show);
    });

    hideEvents.forEach((event) => {
      button.addEventListener(event, hide);
    });
  }

  // form /about attachment
  $('form input[type=file]').on('change', function (e) {
    // "Выбрать файл" в форме
    let f = e.target.files, $l = $('label[for=' + this.id + ']');

    if (!$l.data('default')) {
      $l.data('default', $l.html());
    }

    if (typeof f[0] === 'undefined') {
      $l.html($l.data('default'));
    } else {
      $l.html(f[0].name);
    }
  });
  //.

  // /#…
  if (location.hash !== '') {
    // hide menu
    $body.removeClass('js--main-show');
    $('.menu--active').removeClass('menu--active');
    $('.fog').removeClass('fog--active');
    $('.header__link--active').removeClass('header__link--active')

    let id = location.hash;
    $('html, body').animate({
      scrollTop: $(id).offset().top - $('.header').height()
    }, 4);
  }

  // lazy video
  (function () {
    let lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));

    if ("IntersectionObserver" in window) {
      let lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (video) {
          if (video.isIntersecting) {
            for (let source in video.target.children) {
              let videoSource = video.target.children[source];
              if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                videoSource.src = videoSource.dataset.src;
              }
            }

            video.target.load();
            video.target.classList.remove("lazy");
            lazyVideoObserver.unobserve(video.target);
          }
        });
      });

      lazyVideos.forEach(function (lazyVideo) {
        lazyVideoObserver.observe(lazyVideo);
      });
    }
  })();
});

document.addEventListener("turbo:before-cache", () => {
  console.log('slick destroy in turbo:before-cache');
  const sliders = document.querySelectorAll('.slick-initialized');

  sliders.forEach(item => {
    $(item).slick('unslick');
  });
});
